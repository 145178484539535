import React from 'react';
import styles from './PFSContact.module.css';
import ContentContainer from '../ContentContainer/ContentContainer';
import PFSDiamondBarMap from '../PFSDiamondBarMap/PFSDiamondBarMap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faFax, faMailReplyAll, faPhone } from '@fortawesome/free-solid-svg-icons';
import PFSContactForm from '../PFSContactForm/PFSContactForm';
import { useBreadcrumb } from '../../hooks/useBreadcrumb';

const PFSContact = () => {
  useBreadcrumb([{
    label: 'Contact',
    url: '/contact'
  }])

  return (
    <div className={styles.PFSContact} data-testid="PFSContact">
      <ContentContainer>
        <div id={styles.ContactContainer}>
          <center><p id={styles.TitleContainer}>Get in touch!</p></center>
          <div id={styles.ContactInfoContainer}>
            <div className={styles.InfoContainer}>
              <p>
                <FontAwesomeIcon icon={faPhone} />
                <span>Phone:</span> <a href='tel:19092947372'>909.294.7372</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faFax} />
                <span>Fax:</span> 888.729.9947
              </p>
              <p>
                <FontAwesomeIcon icon={faMailReplyAll} />
                <span>Email:</span> <a href='mailto:info@pfsonline.com'>info@pfsonline.com</a>
              </p>
            </div>
            <div className={styles.InfoContainer}>
              <p><FontAwesomeIcon icon={faClock} /> <span>Open Hours:</span></p>
              <div id={styles.HoursContainer}>
                <p className={styles.HourTitle}>Monday to Friday</p>
                <p>8:00am and 6:30pm Pacific Time</p>
                <p>*Excluding Holidays</p>
                <p className={styles.HourTitle}>Saturdays - Sunday</p>
                <p>By appointment</p>
              </div>
            </div>
          </div>
          <div id={styles.ContactFormContainer}>
            <PFSContactForm />
          </div>
        </div>
        <PFSDiamondBarMap />
      </ContentContainer>
    </div>
  )
};

PFSContact.propTypes = {};

PFSContact.defaultProps = {};

export default PFSContact;
